



























































import Vue from 'vue'
import Component from 'vue-class-component';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { BTable } from 'bootstrap-vue';
import { TemplateAttachment, AttachmentListItem, Template } from '../store/types';
import { FileObject } from '@/modules/genprox/store/types';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { AxiosRequestConfig } from 'axios';
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { BACKEND_BASE_URL } from '@/shared/consts';
import GenproxButton from '@/components/buttons/GenproxButton.vue';

@Component({
  components: { SygniContainerTitle, SygniRectButton, SygniFileBox, SygniInput, SygniModal, GenproxModal, BTable, SygniCheckbox, GenproxButton }
})
export default class Attachments extends Vue {
  @Prop({default: null}) template: Template;
  @Prop({default: []}) attachments: TemplateAttachment[];
  @Prop({default: 'edit'}) editMode: 'edit' | 'preview' | 'template';
  @Prop({default: false}) readOnlyMode: boolean;
  @Prop({ default: 'attachment' }) type: 'attachment' | 'document';
  @Prop() manageProductsEnabled: boolean;
  @Prop({default: true}) allowTemplates: boolean;
  @Prop({default: true}) protected: boolean;
  @Prop({default: 'Upload new files'}) uploadBtnText: string;
  @Prop({default: false}) singleFileMode: boolean;
  @Prop({default: false}) showPreviewAlways: boolean;
  @Prop({default: false}) handlePaths: boolean;
  @Prop({default: false}) showAutentiAction: boolean;

  areFilesLoading: boolean = false;
  attachmentsToUpload: TemplateAttachment[] = [];
  uploadProgress: UploadProgress = { progress: 0 };
  attachmentListItems: AttachmentListItem[] = [];
  isAttachmentModalLoading: boolean = false;
  editableAttachmentIndex: number | null = null;
  showAttachmentsModal: boolean = false;

  get enableNameChange() {
    if (this.singleFileMode) {
      return false
    }

    return !this.isReadOnlyMode
  }

  get showFileUpload() {
    if (this.singleFileMode) {
      return this.attachmentsToUpload?.length === 0 ? true : false
    }

    return true
  }

  get readOnly() {
    return (this.editMode === 'preview' || this.readOnlyMode);
  }

  get attachmentsToPost() {
    const ids = this.template?.attachments?.map((el: TemplateAttachment) => el.referenceId);

    return this.attachmentsToUpload?.filter((el: TemplateAttachment) => !ids.includes(el.referenceId))
  }

  get attachmentsToDelete() {
    const ids = this.template?.attachments?.map((el: TemplateAttachment) => el.id);
    const attachmentsToUpload = this.attachmentsToUpload?.map((el: TemplateAttachment) => el.id);

    return ids?.filter((id: string) => !attachmentsToUpload?.includes(id))
  }

  get attachmentsToEdit() {
    const idsToInclude = this.template?.attachments?.map((el: TemplateAttachment) => el.id);
    const attachments: TemplateAttachment[] = [];

    idsToInclude.forEach((id: string) => {
      const attachment: TemplateAttachment = this.attachmentsToUpload?.find((el: TemplateAttachment) => el.id === id);
      const foundAttachment: TemplateAttachment = this.template?.attachments?.find((el: TemplateAttachment) => el.id === id)
      if(attachment && (foundAttachment?.name !== attachment?.name || foundAttachment?.sendToAutenti !== attachment?.sendToAutenti)) {
        attachments.push(attachment);
      }
    });

    return attachments;
  }

  get filteredAttachmentListItems() {
    const selectedAttachmentIds: string[] = this.attachmentsToUpload?.map((el: TemplateAttachment) => el.referenceId)

    return this.attachmentListItems.filter((el: AttachmentListItem) => !selectedAttachmentIds.includes(el.referenceId));
  }

  get fileBoxAction() {
    if(this.editMode === 'preview') {
      return '';
    }

    return 'DELETE';
  }

  handlePreview(file: any) {
    if (this.editMode === 'preview' || this.isSupportedFile(file)) {
      this.$emit('openAttachment', file)
    }
  }

  keydownHandler(e: any) {
    // check if dot is pressed
    if(e.keyCode == 190 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.path}`, file.name);
  }

  getFileBoxTooltip(file: any) {
    if (this.editMode === 'preview') {
      return '';
    }

    if (this.editMode === 'template') {
      const attachmentIds = this.attachments.map((el: any) => {
        return el.id
      });

      if (attachmentIds.includes(file.id)) {
        return '';
      } else {
        return 'Delete';
      }
    }


    return 'Delete';
  }

  getFileBoxAction(file: any) {
    if(this.editMode === 'preview') {
      return '';
    }

    if(this.editMode === 'template') {
      const attachmentIds = this.attachments.map((el: any) => {
        return el.id
      });

      if(attachmentIds.includes(file.id)) {
        return '';
      } else {
        return 'DELETE';
      }
    }


    return 'DELETE';
  }

  get isReadOnlyMode() {
    if(this.editMode === 'preview') {
      return true;
    }

    if(this.editMode === 'template') {
      return true;
    }

    return false;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  handleFileBoxAction(index: number) {
    if(this.editMode === 'preview') return;

    this.deleteFile(index);
  }

  handleFileBoxesOutsideClick(resetEditable: boolean = true) {
    this.attachmentsToUpload?.forEach((attachment: TemplateAttachment, index: number) => {
      ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).displayValue = attachment.name;
    })
    if(resetEditable) {
      this.editableAttachmentIndex = null;
    }
  }

  isSupportedFile(file: any) {
    const extension = (file?.name?.includes('.')) ? file?.name?.split('.').pop() : '';

    if (extension?.toLowerCase() === 'pdf' && file?.path) {
      return true;
    }

    return (!extension) ? true : false;
  }

  setSendToAutenti(file: any) {
    this.$set(file, 'sendToAutenti', !file?.sendToAutenti)
    this.$emit('change', this.attachmentsToUpload);
    this.$emit('update');
  }

  handleExtension(attachment: TemplateAttachment, value: string) {
    let hasExtension: boolean = false;
    let extension: string = '';
    
    if(attachment.type === 'FILE') {
      extension = attachment.name.split('.').pop();
      hasExtension = extension ? true : false;
    }

    if(hasExtension) {
      // check if value contains extension and if not add this extension to value
      if(value.slice(value.length - extension.length) !== extension) {
        value = `${value}.${extension}`;
      }
    }

    return value;
  } 

  confirmFileBoxNameChange(index: number) {
    const value = ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).displayValue;
    const attachment: TemplateAttachment = this.attachmentsToUpload[index];
    
    this.attachmentsToUpload[index].name = this.handleExtension(attachment, value);
    this.$emit('change', this.attachmentsToUpload);
    this.$emit('update');
  }

  setEditableAttachmentIndex(index: number) {
    this.editableAttachmentIndex = this.editableAttachmentIndex === index ? null : index;

    if(this.editableAttachmentIndex !== null) {
      this.handleFileBoxesOutsideClick(false);
      this.$nextTick(() => {
        ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).setFocus();
      })
    } else {
      this.confirmFileBoxNameChange(index);
    }
  }

  closeAttachmentsModal() {
    this.showAttachmentsModal = false;
  }

  openAttachmentsModal() {
    this.showAttachmentsModal = true;
  }

  addAttachments() {
    const attachmentsToAdd: TemplateAttachment[] = this.attachmentListItems.filter((el: AttachmentListItem) => el.selected).map((el: AttachmentListItem) => {
      const attachment: any = {
        name: el.name,
        referenceId: el.referenceId,
        type: 'TEMPLATE',
      };

      if (this.showAutentiAction) {
        attachment.sendToAutenti = true
      }

      return attachment; 
    });

    attachmentsToAdd.forEach((attachment: TemplateAttachment) => {
      this.attachmentsToUpload.push(attachment);
    });

    this.resetAttachmentSelections();
    this.closeAttachmentsModal();
    this.$emit('update');
  }

  resetAttachmentSelections() {
    this.attachmentListItems = this.attachmentListItems.map((el: AttachmentListItem) => {
      el.selected = false;
      return el;
    });
  }

  deleteFile(index: number) {
    this.attachmentsToUpload.splice(index, 1);
    this.$emit('update');
    this.$emit('change', this.attachmentsToUpload);
  }

  toggleFileUpload() {
    (this.$refs.fileInput as HTMLInputElement).click()
  }

  async uploadFiles(e: any) {
    const files = Array.from((e.target as HTMLInputElement).files);
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      const action = this.protected ? 'genprox/uploadFile' : 'genprox/uploadPublicFile';
      this.areFilesLoading = true;
      const promises: Array<Promise<any>> = [];
      files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch(action, { file, config }));
      })

      const uploadedFiles = await Promise.all(promises);
      this.areFilesLoading = false;
      uploadedFiles.forEach((file: FileObject) => {
        const attachment: any = {
          name: file.fileName,
          type: 'FILE',
          referenceId: file.id,
        };

        if (this.showAutentiAction) {
          attachment.sendToAutenti = true
        }

        if (this.handlePaths) {
          attachment.path = file.filePath
        }

        this.attachmentsToUpload.push(attachment);
      });
    } catch (e) {
      e;
    }
    e.target.value = '';
    this.uploadProgress.progress = -1;
    this.$emit('update', this.singleFileMode ? this.attachmentsToUpload : undefined);
    this.$emit('change', this.attachmentsToUpload);
  }

  async mounted() {
    if(this.editMode === 'template') {
      if(!this.attachmentsToUpload?.length) {
        this.attachmentsToUpload = _.cloneDeep(this.attachments);
      }
    } else {
      this.attachmentsToUpload = _.cloneDeep(this.attachments);
    }

    if (!this.template?.isAttachment) {
      this.isAttachmentModalLoading = true;
      const items = await this.$store.dispatch('templates/getTemplateChoices', { type: 'attachment', context: this.activeUserData?.context?.id });
      items.forEach((item: Template) => {
        this.attachmentListItems.push({
          selected: false,
          referenceId: item.id,
          name: item.title,
        })
      })
      this.isAttachmentModalLoading = false;
    }
  }

  @Watch('attachments') onAttachmentsUpdate() {
    if(this.editMode === 'template') {
      if(!this.attachmentsToUpload.length) {
        this.attachmentsToUpload = _.cloneDeep(this.attachments);
      }
    } else {
      this.attachmentsToUpload = _.cloneDeep(this.attachments);
    }
    this.$emit('change', this.attachmentsToUpload);
  }
}
