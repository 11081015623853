var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "attachments"
  }, [_c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAttachmentsModal,
      expression: "showAttachmentsModal"
    }],
    staticClass: "attachments-modal",
    attrs: {
      "isLoading": _vm.isAttachmentModalLoading,
      "header": "Add attachments",
      "description": "Select attachments that you want to upload with this template.",
      "cancelText": "Close",
      "confirmText": "Add attachments"
    },
    on: {
      "close": _vm.closeAttachmentsModal,
      "cancel": _vm.closeAttachmentsModal,
      "confirm": _vm.addAttachments
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "uploadFilesTable",
          staticClass: "sygni-b-inner-table modal-table",
          attrs: {
            "innertable": "true",
            "show-empty": true,
            "fields": [{
              key: 'name',
              sortable: false,
              class: ['name']
            }],
            "items": _vm.filteredAttachmentListItems
          },
          scopedSlots: _vm._u([{
            key: "cell(name)",
            fn: function fn(rowData) {
              return [_c('div', {
                staticClass: "text-left"
              }, [_c('sygni-checkbox', {
                staticClass: "secondary",
                model: {
                  value: rowData.item.selected,
                  callback: function callback($$v) {
                    _vm.$set(rowData.item, "selected", $$v);
                  },
                  expression: "rowData.item.selected"
                }
              }, [_vm._v(_vm._s(rowData.item.name))])], 1)];
            }
          }, {
            key: "empty",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-center py-3"
              }, [_vm._v("There are no " + _vm._s(_vm.type === 'attachment' ? 'attachments' : 'documents') + " to show")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _vm._t("heading", function () {
    return [_c('sygni-container-title', [_vm._v(_vm._s(_vm.type === 'attachment' ? 'Attachments' : 'Documents'))])];
  }), !_vm.readOnly ? _c('div', {
    staticClass: "attachments-btns"
  }, [_c('GenproxButton', {
    attrs: {
      "outline": true,
      "isDisabled": !_vm.showFileUpload,
      "isLoading": _vm.areFilesLoading
    },
    on: {
      "click": _vm.toggleFileUpload
    }
  }, [_vm._v(" " + _vm._s(_vm.uploadBtnText) + " ")]), _vm.editMode === 'edit' && _vm.allowTemplates ? _c('GenproxButton', {
    attrs: {
      "outline": true
    },
    on: {
      "click": _vm.openAttachmentsModal
    }
  }, [_vm._v(" Add " + _vm._s(_vm.type === 'attachment' ? 'attachments' : 'documents') + " ")]) : _vm._e()], 1) : _vm._e(), !_vm.readOnly ? _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "multiple": !_vm.singleFileMode,
      "hidden": ""
    },
    on: {
      "input": _vm.uploadFiles
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleFileBoxesOutsideClick,
      expression: "handleFileBoxesOutsideClick"
    }],
    staticClass: "attachments-listing"
  }, [_vm.attachmentsToUpload.length == 0 ? _c('div', {
    staticClass: "attachments-empty"
  }, [_c('p', {
    staticClass: "attachments-empty-header"
  }, [_vm._v("No uploaded " + _vm._s(_vm.type === 'attachment' ? 'attachments' : 'documents') + " yet")])]) : _vm._e(), _vm._l(_vm.attachmentsToUpload, function (file, index) {
    return _c('sygni-file-box', {
      key: index,
      class: [_vm.editableAttachmentIndex === index ? 'active' : ''],
      attrs: {
        "actionTooltip": _vm.getFileBoxTooltip(file),
        "icon": _vm.getFileBoxAction(file),
        "readOnlyMode": _vm.readOnlyMode
      },
      on: {
        "click": function click($event) {
          return _vm.handleFileBoxAction(index);
        }
      },
      scopedSlots: _vm._u([{
        key: "additionalActions",
        fn: function fn() {
          return [_vm.showAutentiAction ? _c('div', {
            staticClass: "sygni-file-box__column"
          }, [file !== null && file !== void 0 && file.sendToAutenti ? _c('img', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Should be sent to Autenti',
              expression: "'Should be sent to Autenti'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "autenti-btn",
            attrs: {
              "src": _vm._f("getIcon")('ACCEPT'),
              "alt": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setSendToAutenti(file);
              }
            }
          }) : _c('img', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Shouldn\'t be sent to Autenti',
              expression: "'Shouldn\\'t be sent to Autenti'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "autenti-btn",
            attrs: {
              "src": _vm._f("getIcon")('REJECT'),
              "alt": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setSendToAutenti(file);
              }
            }
          })]) : _vm._e(), _vm.editMode === 'preview' || _vm.isSupportedFile(file) || _vm.showPreviewAlways ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: !(_vm.editMode === 'preview' || _vm.isSupportedFile(file)) ? '' : 'Preview',
              expression: "!(editMode === 'preview' || isSupportedFile(file)) ? '' : 'Preview'",
              modifiers: {
                "hover": true
              }
            }],
            class: ['sygni-file-box__action', !(_vm.editMode === 'preview' || _vm.isSupportedFile(file)) ? 'sygni-file-box__action--disabled' : ''],
            on: {
              "click": function click($event) {
                return _vm.handlePreview(file);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('Fund'),
              "alt": "Preview"
            }
          })]) : _vm._e(), _vm.enableNameChange ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: _vm.editableAttachmentIndex === index ? 'Set name' : 'Edit name',
              expression: "(editableAttachmentIndex === index) ? 'Set name' : 'Edit name'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                return _vm.setEditableAttachmentIndex(index);
              }
            }
          }, [(_vm.editableAttachmentIndex === index ? false : true) ? _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('EDIT'),
              "alt": "Edit"
            }
          }) : _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('APPROVE'),
              "alt": "Confirm"
            }
          })]) : _vm._e(), file.path && _vm.manageProductsEnabled ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Download',
              expression: "'Download'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                return _vm.downloadFileByUrl(file);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('DOWNLOAD'),
              "alt": "Download"
            }
          })]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('sygni-input', {
      ref: "file-".concat(index),
      refInFor: true,
      attrs: {
        "value": file.name,
        "disabled": _vm.editableAttachmentIndex === index ? false : true
      },
      on: {
        "keydown": _vm.keydownHandler,
        "keyup": function keyup($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
          return _vm.setEditableAttachmentIndex(index);
        }
      }
    })], 1);
  })], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }